import { routeToUrl } from "@app-routes";
import { BecomeOurUserSection } from "@components/LandingPage/BecomeOurUserSection/BecomeOurUserSection";
import { LandingPageFooter } from "@components/LandingPage/LandingPageFooter";
import { PATRONS_DATA } from "@config/landingPageData";
import { AppInstance } from "@shared/utils/app-instance";
import { useTranslation } from "@translations/use-translation";
import { Button } from "@ui/Button";
import { LandingPageContainer } from "@ui/Container";
import { HeroSection } from "@ui/HeroSection";
import { LandingScreen } from "@ui/Layout/LandingScreen/LandingScreen";
import { Section } from "@ui/Layout/LandingScreen/styles";
import { Patrons } from "@ui/Patrons/Patrons";
import { Text, TextH1, TextH3 } from "@ui/Text";
import { Hide } from "@uxf/core/components/hide";
import { NextPage } from "next";
import { useTheme } from "styled-components";

const Neighbours: NextPage = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (
        <LandingScreen
            ogData={{
                ogTitle: t("page-landing-neighbours:og.title"),
                description: t("page-landing-neighbours:og.description"),
                image: "/static/images/og/neighbours.jpg",
            }}
            pageName="neighbours"
            title={t("page-landing-neighbours:title")}
        >
            <HeroSection
                bkgFilter="linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))"
                bkgImage="/static/images/landingPage/hero-neighbours.jpg"
                contentShadowWidth={0.7}
                contentShadowTransparency={0.7}
                overlayImage="/static/images/functions/neighbours.png"
                mt={theme.spacing.pageTop}
            >
                <TextH1 mb={16} data-testid="ziskejte-prehled">
                    {t("page-landing-neighbours:h1")}
                </TextH1>
                <Text className="subCaption" as="ul" mb={30} mt={10} textFontWeight="medium" textVariant="h4">
                    <Text as="li" mb={10}>
                        {t("page-landing-neighbours:text1")}
                    </Text>
                    <Text as="li" mb={10}>
                        {t("page-landing-neighbours:text2")}
                    </Text>
                    <Text as="li">{t("page-landing-neighbours:text3")}</Text>
                </Text>

                <Button
                    className="button"
                    href={routeToUrl("auth-zone/registration", {})}
                    shadow
                    size="huge"
                    variant="secondary"
                    width={320}
                >
                    {t("page-landing-neighbours:btn1")}
                </Button>
            </HeroSection>
            <LandingPageContainer>
                <Section>
                    <TextH3 mt={30} mb={12} as="h3">
                        {t("page-landing-neighbours:h3")}
                    </TextH3>
                    <Text as="ul" textVariant="large">
                        <Text as="li">{t("page-landing-neighbours:text4")}</Text>
                        <Text as="li">{t("page-landing-neighbours:text5")}</Text>
                        <Text as="li">{t("page-landing-neighbours:text6")}</Text>
                        <Text as="li">{t("page-landing-neighbours:text7")}</Text>
                        <Text as="li">{t("page-landing-neighbours:text8")}</Text>
                        <Text as="li">{t("page-landing-neighbours:text9")}</Text>
                    </Text>
                    <Text as="p" mt={30} mb={30} textVariant="h3" textFontWeight="medium">
                        {t("page-landing-neighbours:text10")}
                    </Text>
                    <Text as="p" mb={theme.legacyGutter(1)} textColor="text" textFontWeight="semiBold" textVariant="h5">
                        {t("page-landing-neighbours:text11")}
                    </Text>
                    <Text as="ul" textVariant="large">
                        <Text as="li">{t("page-landing-neighbours:text12")}</Text>
                        <Text as="li">{t("page-landing-neighbours:text13")}</Text>
                        <Text as="li">{t("page-landing-neighbours:text14")}</Text>
                        <Text as="li">{t("page-landing-neighbours:text15")}</Text>
                    </Text>
                    <Text as="ul" textVariant="large">
                        <Text as="li">{t("page-landing-neighbours:text16")}</Text>
                        <Text as="li">{t("page-landing-neighbours:text17")}</Text>
                        <Text as="li">{t("page-landing-neighbours:text18")}</Text>
                        <Text as="li">{t("page-landing-neighbours:text19")}</Text>
                        <Text as="li">{t("page-landing-neighbours:text20")}</Text>
                    </Text>

                    <Text as="ul" textVariant="large">
                        <Text as="li">{t("page-landing-neighbours:text21")}</Text>
                        <Text as="li">{t("page-landing-neighbours:text22")}</Text>
                        <Text as="li">{t("page-landing-neighbours:text23")}</Text>
                    </Text>
                    <Hide when={AppInstance.isSlovak}>
                        <Patrons
                            data={[
                                PATRONS_DATA.polach,
                                PATRONS_DATA.horacek,
                                PATRONS_DATA.patron3,
                                PATRONS_DATA.patron6,
                            ]}
                        />
                    </Hide>
                </Section>
            </LandingPageContainer>
            <BecomeOurUserSection btnGtmId="neighbours-becomeOurUser" />
            <LandingPageFooter source="neighbours" />
        </LandingScreen>
    );
};

export default Neighbours;
